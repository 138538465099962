import styled$1, { css } from 'styled-components';
import { Select as Select$1, color, colorSet, space } from '@ccg/core';
import '@4rk/modular-system';
import { getTypography } from '../../styles/typography.js';
import 'tinycolor2';
import { forkSpace } from '../../styles/spacing.js';

const Select = styled$1(Select$1)`
  select {
    width: 100%;
    resize: none;
    background: ${color('medium')};
    outline: none;
    padding: ${forkSpace(3)} ${forkSpace(8)} ${forkSpace(2)} ${forkSpace(3)};
    border: none;
    border-radius: 0;
    border-bottom: ${forkSpace(1)} solid ${colorSet('accent')};
    appearance: none;

    option {
      color: inherit;
    }

    &::-ms-expand {
      display: none;
    }

    ${props => props.error && css(["border:", " solid ", ";padding:", " ", " ", " ", ";"], forkSpace(1), colorSet('accent'), forkSpace(2), forkSpace(8), forkSpace(2), forkSpace(2))}
  }

  select::placeholder {
    color: inherit;
    opacity: 0.5;
  }

  label {
    display: block;
    margin-bottom: ${forkSpace(2)};
  }

  .select__labelText,
  select {
    ${getTypography.copy}
    display: block;
  }

  .select__optional {
    margin-left: ${space(2)};
    ${getTypography.small}
  }

  .select__description,
  .select__error {
    display: block;
    ${getTypography.small}
  }

  .select__error {
    color: ${colorSet('accent')};
  }

  .select__wrapper {
    position: relative;
    display: inline-block;
  }

  .select__custom-icon {
    pointer-events: none;
    position: absolute;
    top: 50%;
    right: ${forkSpace(3)};
    transform: translateY(-50%);
    border-left: ${space(1.5)} solid transparent;
    border-right: ${space(1.5)} solid transparent;
    border-top: ${space(2)} solid ${colorSet('accent')};
  }
`;

export default Select;
