import styled$1 from 'styled-components';
import { space, mq } from '@ccg/core';

const StyledLogoWall = styled$1.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: ${space(15)} 0 ${space(15)} 1px;

  ${mq('m')} {
    margin: ${space(20)} 0 ${space(20)} 1px;
  }

  .logoWall__client {
    position: relative;
    width: calc(50% + 1px);
    margin-left: -1px;
    margin-top: -1px;

    ${mq('m')} {
      width: calc(25% + 1px);
    }

    ${mq('l')} {
      width: calc(20% + 1px);
    }

    p {
      text-align: center;
      margin-bottom: 0;
      padding: 0 ${space(2)};
    }
  }

  .logoWall__client-inner {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 50%;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      max-width: 80%;
      max-height: 80%;
      transform: translate(-50%, -50%);
    }
  }
`;

export default StyledLogoWall;
