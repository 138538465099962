import styled$1, { css } from 'styled-components';
import { getProperty, colorSet, mq } from '@ccg/core';
import { unit } from '@4rk/modular-system';
import '../../styles/typography.js';
import 'tinycolor2';
import { forkSpace } from '../../styles/spacing.js';

const StyledTeam = styled$1.div`
  width: 100%;
  margin: ${forkSpace(35)} 0;

  .team__companies {
    margin: ${forkSpace(6)} 0;
    text-align: center;

    a {
      display: inline-block;
    }
  }
`;
const StyledTeamMembers = styled$1.div`
  ${getProperty('grid-column', {
  default: ' 1 / span 6',
  m: ' 2 / span 10'
})}
  text-align: center;

  button {
    text-transform: uppercase;
    margin: ${forkSpace(2)};
    color: ${colorSet('color')};
    cursor: pointer;

    p,
    span {
      display: inline-block;
    }

    span {
      margin-left: ${forkSpace(2)};
      transform-origin: center;
      transition: transform ${unit(['gravity', 'volume'], 'transitionDuration')} ease-in-out;

      ${props => props.isVisible && css(["transform:rotate(180deg) translateY(2px);"])}
    }
  }

  .teamMembers__list {
    display: ${props => props.isVisible ? 'flex' : 'none'};
    flex-wrap: wrap;
    justify-content: center;
  }

  .teamMembers__member {
    display: inline-block;
    width: 100%;
    margin: 0 ${forkSpace(6)} ${forkSpace(6)};
    text-align: center;

    ${mq('s')} {
      margin: ${forkSpace(6)};
      width: auto;
    }
  }
`;

export { StyledTeam, StyledTeamMembers };
