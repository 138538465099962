import styled$1, { css } from 'styled-components';
import { Upload as Upload$1, colorSet } from '@ccg/core';
import '@4rk/modular-system';
import { getTypography } from '../../styles/typography.js';
import 'tinycolor2';
import { forkSpace } from '../../styles/spacing.js';

const Upload = styled$1(Upload$1)`
  input {
    display: block;
    width: 100%;
    outline: none;
    border: none;
    box-sizing: border-box;

  /* ${props => props.error && css([""])} */
  }

  input::placeholder {
    color: inherit;
    opacity: 0.5;
  }

  label {
    display: block;
    margin-bottom: ${forkSpace(2)};
  }

  .upload__labelText,
  input {
    ${getTypography.copy}
  }

  .upload__optional {
    margin-left: ${forkSpace(2)};
    ${getTypography.small}
  }

  .upload__description,
  .upload__error {
    display: block;
    ${getTypography.small}
  }

  .upload__error {
    color: ${colorSet('accent')};
  }
`;

export default Upload;
