/*
  Do not edit directly
  Run `yarn tokens` to generate new variables from design tokens
*/
const tokens = {
  breakpoints: {
    s: '420px',
    m: '768px',
    l: '1024px'
  },
  colors: {
    bright: '#f23e23',
    light: '#f7f3f2',
    medium: '#E4E2E2',
    dark: '#020000',
    neutral: '#8f8786'
  },
  colorSets: {
    technical: {
      color: '#020000',
      colorSecondary: '#f7f3f2',
      background: '#f7f3f2',
      backgroundSecondary: '#020000',
      accent: '#8f8786',
      neutral: '#8f8786'
    },
    editorial: {
      color: '#020000',
      colorSecondary: '#f7f3f2',
      background: '#f7f3f2',
      backgroundSecondary: '#020000',
      accent: '#f23e23',
      neutral: '#8f8786'
    },
    neutral: {
      color: '#f7f3f2',
      colorSecondary: '#020000',
      background: '#020000',
      backgroundSecondary: '#f7f3f2',
      accent: '#f23e23',
      neutral: '#8f8786'
    },
    advertising: {
      color: '#f7f3f2',
      colorSecondary: '#020000',
      background: '#f23e23',
      backgroundSecondary: '#f7f3f2',
      accent: '#f23e23',
      neutral: '#8f8786'
    }
  },
  grid: {
    columns: {
      default: 6,
      m: 12
    },
    gutters: {
      default: 0
    }
  },
  modular: {
    values: {
      mass: 0.5,
      size: 0.5,
      density: 0.5,
      form: 0.5,
      timbre: 0.5,
      rhythm: 0.5,
      tonality: 0.5,
      volume: 0.5,
      speed: 0.5,
      gravity: 0.5,
      acceleration: 0.5,
      flexibility: 0.5
    },
    ranges: {
      mobileFontRatio: [1.2, 1.53, 1.53],
      desktopFontRatio: [1.2, 1.618, 1.618],
      fontFamily: [{
        accent: '"Chivo", sans-serif',
        accentItalic: '"Chivo", sans-serif',
        default: '"Chivo", sans-serif'
      }, {
        accent: '"Malabar LT W01 Regular", serif',
        accentItalic: '"Malabar LT W01 Italic", serif',
        default: '"Chivo", sans-serif'
      }, {
        accent: '"Malabar LT W01 Regular", serif',
        accentItalic: '"Malabar LT W01 Italic", serif',
        default: '"Chivo", sans-serif'
      }, {
        accent: '"Malabar LT W01 Regular", serif',
        accentItalic: '"Malabar LT W01 Italic", serif',
        default: '"Chivo", sans-serif'
      }],
      spacingBase: [2, 4, 4],
      gridGap: ['30px', '0px', '0px'],
      fontWeigth: [400],
      saturation: [100],
      parallax: [1, 2],
      parallaxDistance: [10, 40],
      tonality: [4, 8],
      volume: ['40dB', '60dB'],
      timbre: ['140Hz', '140Hz'],
      rhythm: ['80bpm', '100bpm'],
      transitionDelay: ['0s', '0.5s'],
      gravity: ['9.8G'],
      transitionDuration: ['1s', '0.4s'],
      cubicBezier: ['0.54, 0.0, 0.54, 1', '1, 0.0, 1, 1'],
      flexibilityCubicBezier: ['0.35, 0.0, 0.4, 1']
    }
  },
  spacing: {
    base: 4,
    scale: {
      ratio: 2,
      base: 4
    }
  },
  typography: {
    scale: {
      ratio: [1.53, 1.618],
      base: [16, 17],
      width: [320, 980],
      breakpoints: 12,
      corrections: {
        default: {
          1: [-1, 0],
          3: [-20, 0]
        },
        accent: {
          3: [3, -2],
          4: [-29, 0],
          5: [-77, -5]
        },
        accentItalic: {
          2: [-14, 0]
        }
      }
    }
  },
  fonts: {
    default: {
      family: '"Chivo", sans-serif'
    },
    accent: {
      family: '"Malabar LT W01 Regular", serif'
    },
    accentItalic: {
      family: '"Malabar LT W01 Italic", serif'
    }
  },
  zIndexes: {
    bottom: -1,
    base: 1,
    middle: 2,
    top: 3,
    modal: 99,
    overlay: 999
  }
};

export { tokens };
