import styled$1, { css } from 'styled-components';
import { mq, getProperty } from '@ccg/core';
import '@4rk/modular-system';
import '../../styles/typography.js';
import 'tinycolor2';
import { forkSpace } from '../../styles/spacing.js';

const StyledTeaserContainer = styled$1.div`
  position: relative;
  margin-bottom: ${forkSpace(16)};

  .teaserContainer__wrapper {
    position: relative;
    margin-bottom: ${forkSpace(8)};
    column-gap: 1px;

    ${mq('m')} {
      grid-auto-flow: dense;
    }

    ${props => props.filtering && css(["opacity:0.3;"])}
  }

  .teaserContainer__spinner {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  .teaserContainer__no-results {
    grid-column: 1 / -1;

    ${mq('m')} {
      grid-column: 2 / span 10;
    }

    button {
      margin-top: ${forkSpace(8)};
    }
  }

  .teaser {
    ${getProperty('grid-column', {
  default: '1 / span 6'
})};
    margin-top: ${forkSpace(22)};

    &:nth-child(odd) {
      ${mq('m')} {
        grid-column: 7 / span 6;
        margin-top: ${forkSpace(35)};
      }
    }
  }

  .teaserHero {
    ${getProperty('grid-column-end', {
  default: 'span 6',
  m: 'span 9'
})};

    &:nth-child(n + 2) {
      margin-top: ${forkSpace(24)};
    }
  }

  .teaserJob {
    ${getProperty('grid-column', {
  default: '1 / span 6',
  m: '2 / span 5'
})};

    &:nth-child(even) {
      ${getProperty('grid-column', {
  default: '1 / span 6',
  m: '7 / span 5'
})};
    }
  }

  .teaserContainer__load-more {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${forkSpace(12)} ${forkSpace(4)};
  }
`;

export default StyledTeaserContainer;
