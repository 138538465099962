import styled$1 from 'styled-components';
import { zIndex, mq, color } from '@ccg/core';
import '@4rk/modular-system';
import { getTypography } from '../../styles/typography.js';
import 'tinycolor2';
import { forkSpace } from '../../styles/spacing.js';

const StyledCookieLayer = styled$1.div`
  position: fixed !important;
  max-width: 350px;
  z-index: ${zIndex('modal')};
  bottom: 0;
  left: 0;
  width: 100%;
  display: block;

  ${mq('s')} {
    max-width: 420px;
  }

  .cookieLayer__content {
    position: relative;
    padding: ${forkSpace(4)};
    background: ${color('medium')};
    color: #000;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: -80px;
      width: 150px;
      height: 100%;
      transform: skew(20deg, 0deg);
      transform-origin: left bottom;
      background: ${color('medium')};
    }
  }

  .cookieLayer__text {
    position: relative;

    p {
      ${getTypography.small};
    }
  }

  .cookieLayer__actions {
    position: relative;
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
  }

  .cookieLayer__button {
    margin-top: ${forkSpace(4)};
    text-align: left;

    &:first-child {
      margin-right: ${forkSpace(8)};
    }
  }
`;

export default StyledCookieLayer;
