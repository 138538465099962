import { objectSpread2 as _objectSpread2 } from '../_virtual/_rollupPluginBabelHelpers.js';
import tinycolor$1 from 'tinycolor2';

const getColorSet = (colors, colorSets, clientCi) => {
  const readableColors = tinycolor$1.isReadable(colors.light, clientCi.background || colors.bright, {
    level: 'AA',
    size: 'large'
  }) ? {
    color: colors.light,
    colorSecondary: colors.dark,
    backgroundSecondary: colors.light
  } : {
    color: colors.dark,
    colorSecondary: colors.light,
    backgroundSecondary: colors.dark
  };

  const advertising = _objectSpread2(_objectSpread2({}, readableColors), {}, {
    background: clientCi.background || colors.bright,
    accent: clientCi.accent || colors.dark,
    neutral: colors.neutral
  });

  return _objectSpread2(_objectSpread2({}, colorSets), {}, {
    advertising
  });
};

export { getColorSet };
