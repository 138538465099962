import styled$1, { css } from 'styled-components';
import { space, getProperty, mq, colorSet } from '@ccg/core';
import { getGrid } from '../Grid/Grid.js';

const StyledFilter = styled$1.aside`
  margin: ${space(20)} 0;

  .filter__selected,
  .filter__groups,
  .filter__values {
    ${getProperty('grid-column', {
  default: '1 / span 6',
  m: '2 / span 10'
})}
  }

  .filter__selected {
    position: relative;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    padding-top: ${space(2)};

    ${mq('m')} {
      align-items: center;
      flex-direction: row;
    }

    &:before {
      opacity: ${props => props.open ? 1 : 0};
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      height: 2px;
      width: 120px;
      background: ${colorSet('accent')};
      transition: opacity 0.2s ease-in-out;
    }

    button {
      display: inline-flex;
      align-items: center;
      margin-right: ${space(6)};
      color: ${colorSet('accent')};
      padding: ${space(2)} 0;

      &:focus,
      &:hover {
        outline: none;
        color: ${colorSet('accent')};

        svg {
          fill: ${colorSet('accent')};
        }
      }

      ${mq('m')} {
        padding: 0;
      }
    }
  }

  .filter__selected-title {
    color: ${colorSet('accent')};
    margin-right: ${space(6)};
  }

  .filter__values {
    display: none;

    button {
      ${getProperty('grid-column', {
  default: 'span 6',
  m: 'span 2'
})}
      text-align: left;
      display: block;
      padding: ${space(3)} 0;
      padding-right: ${space(2)};
      color: ${colorSet('color')};

      &:hover {
        color: ${colorSet('accent')};
      }

      &[aria-pressed='true'],
      &:focus {
        outline: none;
        color: ${colorSet('accent')};
      }

      ${mq('m')} {
        padding: ${space(4)} 0;
        padding-right: ${space(2)};
      }
    }
  }

  .filter__values[aria-hidden='false'] {
    ${getGrid({
  default: 6,
  m: 10
}, false)};
  }

  .filter__groups {
    width: 100%;
    display: flex;
    align-items: center;

    &-wrapper {
      border-bottom: 2px solid ${colorSet('neutral')};
      overflow-x: scroll;
      overflow-y: hidden;
      -webkit-overflow-scrolling: touch;
      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    button {
      display: inline-flex;
      align-items: center;
      margin-bottom: ${space(2)};
      margin-right: ${space(6)};
      color: ${colorSet('color')};

      ${mq('m')} {
        margin-right: ${space(12)};
      }

      &[aria-pressed='true'],
      &:focus {
        outline: none;
        color: ${colorSet('accent')};
      }

      svg {
        overflow: visible;
      }

      ${props => props.open && css(["&[aria-pressed='false']{color:", ";.icon__icon{border-top-color:", ";}}"], colorSet('neutral'), colorSet('neutral'))}
    }
  }
`;

export default StyledFilter;
