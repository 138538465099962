import styled$1 from 'styled-components';
import { mq, colorSet, getProperty, zIndex, space } from '@ccg/core';
import '@4rk/modular-system';
import { getTypography } from '../../../../styles/typography.js';
import 'tinycolor2';
import { forkSpace } from '../../../../styles/spacing.js';
import { getGrid, getOuterMargins } from '../../../Grid/Grid.js';

/* eslint-disable no-irregular-whitespace */
const StyledControls = styled$1.div`
  .controls__next,
  .controls__prev {
    display: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    ${mq('m')} {
      display: block;
    }
  }

  .controls__next  {
    right: ${forkSpace(3)};
    text-align: right;
  }

  .controls__prev {
    left: ${forkSpace(3)};
    text-align: left;
  }

  .controls__close {
    grid-row: 1 / span 2;
    text-transform: uppercase;
    text-align: right;
    align-self: end;
    color: ${colorSet('color')};
    ${getTypography.copy};
    ${getProperty('grid-column', {
  default: '11 / span 1'
})}
  }

  .controls__header {
    ${getGrid()}
    ${getOuterMargins()}
    width: calc(100% - ${forkSpace(12)});
    top: ${forkSpace(5)};
    position: absolute;
    z-index: ${zIndex('base')};

    ${mq('m')} {
      width:100%
    }
  }

  .controls__content {
    ${getGrid()}
    ${getOuterMargins()}
    width: calc(100% - ${forkSpace(12)});
    position: absolute;
    z-index: ${zIndex('base')};
    bottom: ${forkSpace(5)};

    ${mq('m')} {
      width:100%
    }
  }

  .controls__caption,
  .controls__bullets {
    ${getProperty('grid-column', {
  default: '1 / span 5',
  m: '2 / span 4'
})}
  }

  .controls__caption {
    grid-row: 1;
    margin-bottom: ${forkSpace(9)};
  }

  .controls__bullets {
    grid-row: 2;
    
    button {
      margin-right: ${forkSpace(3)};
    }

    span {
      width: ${space(2)};
      height: ${space(2)};
      background: ${colorSet('color')};
      display: block;
      transition: width 0.2s ease-in-out;

      &.is-active {
        background: ${colorSet('accent')};
        width: ${space(12)};
      }
    }
  }
`;

export default StyledControls;
