import styled$1, { css } from 'styled-components';
import { mq } from '@ccg/core';
import '@4rk/modular-system';
import '../../styles/typography.js';
import 'tinycolor2';
import { forkSpace } from '../../styles/spacing.js';
import { getGrid } from '../Grid/Grid.js';
import { getFramerAlignment, getImageAlignment, getCaptionAlignment } from './helper/index.js';

/* eslint-disable indent */

const getFramerMargins = ({
  variant
}) => {
  return variant === 'l' || variant === 'xl' ? css(["align-self:start;margin-top:", ";", "{margin-top:", ";}"], forkSpace(-12), mq('l'), forkSpace(-18)) : css(["align-self:end;margin-bottom:", ";", "{margin-bottom:", ";}"], forkSpace(-12), mq('l'), forkSpace(-18));
};

const StyledMediaContent = styled$1.div`
  ${getGrid(null, true)};
  position: relative;
  padding: 0;

  ${props => props.variant === 'l' || props.variant === 'xl' ? css(["margin-top:", ";margin-bottom:", ";", "{margin-top:", ";margin-bottom:", ";}"], forkSpace(34), forkSpace(22), mq('m'), forkSpace(44), forkSpace(32)) : css(["margin-top:", ";margin-bottom:", ";", "{margin-top:", ";margin-bottom:", ";}"], forkSpace(22), forkSpace(32), mq('m'), forkSpace(32), forkSpace(44))}

  .mediaContent__framer-wrapper {
    ${getFramerAlignment}
    ${getFramerMargins}
    grid-row: 1;
    pointer-events: none;
  }

  .mediaContent__media-wrapper {
    ${getImageAlignment}
    align-self: center;

    > div {
      filter: ${props => props.shadow && 'drop-shadow(10px 10px 80px rgba(2,0,0,0.3))'};
    }

    img,
    video {
      width: 100%;
    }
  }

  .mediaContent__meta {
    ${getCaptionAlignment}
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin: ${forkSpace(18)} 0;

    ${mq('m')} {
      margin: ${forkSpace(4)} 0;
    }
  }
`;

export default StyledMediaContent;
