import styled$1, { css } from 'styled-components';
import { Radio as Radio$1, hideVisually, mq, colorSet, zIndex } from '@ccg/core';
import '@4rk/modular-system';
import { getTypography } from '../../styles/typography.js';
import 'tinycolor2';
import { forkSpace } from '../../styles/spacing.js';

const getHexagon = (width, triangleHeight, col) => css(["width:", "px;height:", "px;background-color:", ";position:relative;&:before{content:' ';width:0;height:0;border-bottom:", "px solid ", ";border-left:", "px solid transparent;border-right:", "px solid transparent;position:absolute;top:-", "px;}&:after{content:'';width:0;position:absolute;bottom:-", "px;border-top:", "px solid ", ";border-left:", "px solid transparent;border-right:", "px solid transparent;}"], width, width / 2, col, triangleHeight, col, width / 2, width / 2, triangleHeight, triangleHeight, triangleHeight, col, width / 2, width / 2);

const Radio = styled$1(Radio$1)`
  input {
    ${hideVisually}
  }

  label {
    display: flex;
    align-items: center;
    margin-bottom: ${forkSpace(2)};
    cursor: pointer;
  }

  .radio__options {
    display: flex;
    flex-wrap: wrap;

    ${mq('m')} {
      flex-direction: ${props => props.alignment === 'horizontal' ? 'row' : 'column'};
    }
  }

  .radio__item {
    flex-shrink: 0;
    margin-right: ${forkSpace(4)};

    label {
      display: flex;
      flex-direction: ${props => props.variant === 'right' ? 'row-reverse' : 'row'};
      justify-content: ${props => props.variant === 'right' ? 'flex-end' : 'flex-start'};
    }
  }

  .radio__custom-icon {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0;

    ${props => props.variant === 'right' ? css(["margin-left:", ";"], forkSpace(2)) : css(["margin-right:", ";"], forkSpace(2))}

    ${getHexagon(32, 10, colorSet('neutral'))}
  }

  .radio__custom-icon-inner {
    display: none;
    z-index: ${zIndex('base')};

    ${getHexagon(16, 5, colorSet('accent'))}
  }

  .radio__label {
    ${getTypography.copy}
    display: block;
  }

  .radio__optional {
    margin-left: ${forkSpace(2)};
    ${getTypography.small}
  }

  .radio__error {
    display: block;
    color: ${colorSet('accent')};
    ${getTypography.small}
  }

  input:checked + label .radio__custom-icon-inner {
    display: block;
  }

  input[disabled] + label {
    opacity: 0.5;
    pointer-events: none;

    .radio__custom-icon {
      ${getHexagon(32, 10, colorSet('color'))}

      .radio__custom-icon-inner {
        display: block;
        ${getHexagon(28, 8, colorSet('background'))}
      }
    }
  }
`;

export default Radio;
