import styled$1 from 'styled-components';
import { SpringParallax } from '@ccg/core';
import '@4rk/modular-system';
import '../../styles/typography.js';
import 'tinycolor2';
import { forkSpace } from '../../styles/spacing.js';
import { getTextColumns } from '../../basics/Text/helper/getGridColumns.js';
import { getGrid, getOuterMargins } from '../Grid/Grid.js';

const StyledInlineContact = styled$1(SpringParallax)`
  ${getGrid()}
  margin: ${forkSpace(10)} 0 ${forkSpace(22)};
  ${getOuterMargins()}


  .inlineContact__wrapper {
    ${getTextColumns}
  }
`;

export default StyledInlineContact;
