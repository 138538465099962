import { css } from 'styled-components';
import { mq } from '@ccg/core';

const getTextColumns = props => {
  if (props.theme.contentType === 'advertising') {
    return css(["grid-column:1 / span 6;", "{grid-column:7 / span 5;}", "{grid-column:6 / span 6;}"], mq('m'), mq('l'));
  }

  return css(["grid-column:1 / span 6;", "{grid-column:2 / span 5;}", "{grid-column:3 / span 5;}"], mq('m'), mq('l'));
};

export { getTextColumns };
