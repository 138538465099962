import { css } from 'styled-components';
import { space, colorSet, mq } from '@ccg/core';
import adaptiveModularScale$1 from 'adaptive-modular-scale';
import { step } from '@4rk/modular-system';

/**
 * Returns adaptive modular font sizes
 * @param  {number} step - Step on the modular scale. Default: 0.
 * @param  {array} corrections - Corrections array. Default: null.
 * @returns {string} Resulting adaptive modular font sizes
 * @example ${fontSize(3, [-1, 2])};
 */

const fontSize = (step = 0, corrections = null) => ({
  theme
}) => {
  const {
    typography
  } = theme;
  const {
    scale: {
      width,
      base,
      ratio,
      breakpoints
    }
  } = typography;
  const corr = corrections ? {
    [step]: corrections
  } : {}; // const mobileFontRatio = modularStep('density', 'mobileFontRatio')({ theme });
  // const desktopFontRatio = modularStep('density', 'desktopFontRatio')({ theme });

  return adaptiveModularScale$1(step, {
    ratio,
    base,
    width,
    breakpoints: parseFloat(breakpoints),
    corrections: corr
  })({
    theme
  });
};
const fontFamily = (key = 'default') => props => {
  const family = step('form', 'fontFamily')(props);
  return `font-family: ${family[key]};`;
};
/**
 * Returns font declarations
 * @param  {number} step - Step on the modular scale. Default: 0.
 * @param  {string} familyKey - Key of font family inside theme.fonts object. Default: "default".
 * @returns {string} Resulting font declarations
 * @example ${font(3, "accent")};
 */

const font = (step = 0, familyKey = 'default') => ({
  theme
}) => {
  const {
    typography: {
      scale: {
        corrections
      }
    }
  } = theme;
  const corr = corrections && corrections[familyKey] ? corrections[familyKey][step] : null;
  const family = fontFamily(familyKey)({
    theme
  });
  const fontSizes = fontSize(step, corr)({
    theme
  });
  return `${family}\n${fontSizes}`;
};
/* ACCENTS */

const getAccentBottomLine = css(["border-bottom:", " solid ", ";background:linear-gradient(180deg,transparent 78%,", " 78%);"], space(1), colorSet('accent'), colorSet('accent'));
const getAccentTopLine = css(["position:relative;margin-top:", ";&:before{content:'';position:relative;background:", ";width:", ";height:", ";margin-bottom:", ";display:block;}"], space(4), colorSet('accent'), space(25), space(2), space(2));
/* COPIES */

const small = css(["", " line-height:1.8;letter-spacing:0.3px;"], font(-1));
const copy = css(["", " line-height:1.65;letter-spacing:0.3px;", ""], font(0), props => props.transform === 'uppercase' && css(["letter-spacing:", ";"], space(0.5)));
const subline = css(["", " line-height:1.42;letter-spacing:0.5;"], font(1));
const subheadline = css(["", " line-height:1.15;letter-spacing:1px;"], font(2));
const quote = css(["", " line-height:1.4;"], font(2, 'accentItalic'));
const intro = css(["", " line-height:1.15;letter-spacing:1px;"], font(3));
const headline = css(["", " line-height:1.15;letter-spacing:1px;"], font(4, 'accent'));
const hero = css(["", " line-height:1.15;letter-spacing:1px;", "{line-height:1.1;}"], font(5, 'accent'), mq('m'));
const getTypography = {
  copy,
  intro,
  small,
  headline: ({
    theme
  }) => theme.contentType === 'technical' ? intro : headline,
  subline,
  subheadline,
  hero,
  quote
};

export { copy, font, fontFamily, fontSize, getAccentBottomLine, getAccentTopLine, getTypography, headline, hero, intro, quote, small, subheadline, subline };
