import styled$1 from 'styled-components';

const StyledSlider = styled$1.div`
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 70vh;

  .slider__slide {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    will-change: transform;
    transition: opacity 0.3s ease-in-out;
    overflow: hidden;
    text-align: center;

    img {
      width: 80%;
      height: 100%;
      user-select: none;
      pointer-events: none;
      object-fit: contain;
      filter: ${({
  imageShadow
}) => imageShadow && 'drop-shadow(10px 10px 80px rgba(2,0,0,0.3))'};
    }
  }
`;

export default StyledSlider;
