import styled$1, { css } from 'styled-components';
import { colorSet, space } from '@ccg/core';
import '@4rk/modular-system';
import { getAccentTopLine, getTypography } from '../../styles/typography.js';
import 'tinycolor2';

const StyledTags = styled$1.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 20px;
  ${getAccentTopLine}

  .tags__accent {
    color: ${colorSet('accent')};
  }

  ${({
  accentColor
}) => accentColor && css(["&:before{background:", ";}.tags__accent{color:", ";}"], accentColor, accentColor)}

  ${({
  horizontal
}) => horizontal && css(["padding-left:", ";flex-direction:row;flex-wrap:wrap;&:before{position:absolute;left:0;top:2px;}span{margin-left:", ";}.tags__tag{", " line-height:1;margin-bottom:", ";}"], space(25), space(2), getTypography.small, space(2))}
`;

export default StyledTags;
