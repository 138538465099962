import styled$1, { css } from 'styled-components';
import { Link, colorSet } from '@ccg/core';
import '@4rk/modular-system';
import { getTypography } from '../../styles/typography.js';
import 'tinycolor2';

const StyledLink = styled$1(Link)`
  position: relative;
  text-transform: ${({
  transform
}) => transform};

  ${({
  variant
}) => getTypography[variant]}

  ${props => props.icon && css(["&:hover{color:", ";background:", ";}"], colorSet('color'), colorSet('accent'))}
`;

export default StyledLink;
