import styled$1 from 'styled-components';
import { SpringParallax, mq, colorSet } from '@ccg/core';
import '../../styles/typography.js';
import 'tinycolor2';
import { forkSpace } from '../../styles/spacing.js';
import { getTransition } from '../../styles/transitions.js';
import { getGrid } from '../Grid/Grid.js';

/* eslint-disable no-irregular-whitespace */
const StyledJobTeaser = styled$1(SpringParallax)`
  width: 100%;
  position: relative;
  ${getTransition}

  &:nth-child(odd) {
    ${mq('m')} {
      margin-top: ${forkSpace(35)};
    }
  }

  &:nth-child(odd) .jobTeaser__link:before  {
    margin-left: ${forkSpace(-6)};

    ${mq('m')} {
      margin-left: 0;
    }
  }

  .jobTeaser__link {
    position: relative;
    padding: ${forkSpace(20)} 0;
    color: ${colorSet('colorSecondary')};
    ${getGrid({
  default: 6,
  m: 5
})}

    ${mq('m')} {
      min-height: ${forkSpace(132)};
    }

    &:before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      height: 100%;
      background: ${colorSet('backgroundSecondary')};
      width: calc(100% + ${forkSpace(6)});

      ${mq('m')} {
        width: 100%;
      }
    }
  }

  .jobTeaser__wrapper {
    position: relative;
    align-self: center;
    grid-column: 2 / span 4;

    ${mq('m')} {
      grid-column: 2 / span 3;
    }
  }

  .jobTeaser__link-text {
    display: inline-block;
    margin-top: ${forkSpace(12)};
    text-transform: uppercase;
    letter-spacing: ${forkSpace(0.5)};
    transition: all 0.2s ease-in-out;

    .link__icon {
      background: ${colorSet('accent')};
    }
  }

  .jobTeaser__locations {
    margin-top: ${forkSpace(4)};
  }

  &:hover .jobTeaser__link-text {
    background: ${colorSet('accent')};
    color: ${colorSet('backgroundSecondary')};
  }
`;

export default StyledJobTeaser;
