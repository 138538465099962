import styled$1, { css } from 'styled-components';
import { AspectRatio, colorSet } from '@ccg/core';
import { unit, cubicBezier } from '@4rk/modular-system';
import '../../styles/typography.js';
import 'tinycolor2';
import { forkSpace } from '../../styles/spacing.js';

const getCaptionAlignment = ({
  alignment
}) => {
  if (alignment === 'right') {
    return css(["display:flex;align-items:flex-end;"]);
  }

  return '';
};

const getRotation = (alignment, inverse) => {
  const right = inverse ? '0' : '180deg';
  const left = inverse ? '180deg' : '0';
  return alignment.toLowerCase().includes('right') ? right : left;
};

const StyledFramer = styled$1(AspectRatio)`
  position: relative;
  box-shadow: inset 0 0 0 ${forkSpace(2)} ${colorSet('color')};

  .framer__caption {
    position: absolute;
    width: 100%;
    padding: ${forkSpace(4)} ${forkSpace(6)};
    transform: rotate(-90deg);
    bottom: 0;
    ${getCaptionAlignment}

    p {
      display: flex;
      align-items: center;
    }
  }

  svg {
    fill: ${colorSet('accent')};
    margin-right: ${forkSpace(2)};
    transform: rotate(90deg);
    width: 15px;
    height: 15px;

    transition: all ${unit('gravity', 'transitionDuration')} linear 0.4s
      ${cubicBezier('acceleration', 'cubicBezier')};
    transform-origin: center center;

    ${({
  isVisible,
  alignment,
  inverse
}) => isVisible && css(["transform:rotate(", ");"], getRotation(alignment, inverse))}
  }

  ${props => props.hasIndex && css([".framer__caption-fig:before{counter-increment:captions;content:'Fig. ' counter(captions);margin-right:", ";}"], forkSpace(1))}
`;

export default StyledFramer;
export { getCaptionAlignment };
