import styled$1, { css } from 'styled-components';
import { Text, colorSet, space, mq } from '@ccg/core';
import '@4rk/modular-system';
import { getAccentTopLine, getTypography } from '../../styles/typography.js';
import 'tinycolor2';
import { forkSpace } from '../../styles/spacing.js';
import { getTextColumns } from './helper/getGridColumns.js';
import { getGrid } from '../../components/Grid/Grid.js';

const StyledText = styled$1(Text)`
  position: relative;
  ${props => props.hasAccent && getAccentTopLine}

  > h2,
  > h3 {
    font-weight: normal;
    margin: 0 0 ${forkSpace(6)};
    ${getTypography.subline}
  }

  > h4 {
    display: inline-block;
    font-weight: normal;
    margin: ${forkSpace(8)} 0 ${forkSpace(6)};
    color: ${colorSet('accent')};
    ${getTypography.copy}
    ${getAccentTopLine}
  }

  ul,
  ol {
    padding: 0;
    margin: 0 0 ${forkSpace(6)};
  }

  li {
    list-style-type: none;
    position: relative;
    margin: 0 0 0 ${space(4)};
    ${getTypography.copy}
  }

  ul li:before {
    position: absolute;
    top: 10px;
    left: ${space(-4)};
    content: '';
    background: ${colorSet('accent')};
    width: ${space(2)};
    height: ${space(2)};
  }

  ol {
    counter-reset: section;

    li::before {
      position: absolute;
      top: 0;
      left: ${space(-4)};
      color: ${colorSet('accent')};
      counter-increment: section;
      content: counters(section, '.') ' ';
    }
  }

  ol ol {
    counter-reset: section;
    margin-bottom: 0;

    li::before {
      counter-increment: section;
      content: counter(section, lower-alpha);
    }
  }

  p {
    margin: 0 0 ${forkSpace(6)};
    ${getTypography.copy}

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    color: ${colorSet('accent')};
    letter-spacing: ${forkSpace(0.5)};
    transition: all 0.2s ease-in-out;

    .text__link-icon-wrapper {
      white-space: nowrap;
    }

    .text__link-icon {
      display: inline-block;
      position: relative;
      vertical-align: text-top;
      width: ${forkSpace(2)};
      height: ${forkSpace(2)};
      margin-left: ${forkSpace(-1)};
      background: ${colorSet('accent')};
    }

    &[target='_blank'] .text__link-icon {
      width: 0;
      height: 0;
      margin-left: ${forkSpace(-2)};
      border-top: ${forkSpace(2)} solid ${colorSet('accent')};
      border-left: ${forkSpace(2)} solid transparent;
      background: transparent;
    }

    &[href^='mailto:'] .text__link-icon,
    &[href^='tel:'] .text__link-icon {
      width: 0;
      height: 0;
      margin-left: ${forkSpace(-2)};
      border-bottom: ${space(2)} solid ${colorSet('accent')};
      border-right: ${space(2)} solid transparent;
      background: transparent;
    }

    &:hover {
      color: ${colorSet('background')};
      background: ${colorSet('accent')};
    }
  }

  b,
  strong {
    font-weight: normal;
    background: ${colorSet('accent')};
    color: ${colorSet('background')};
  }

  .highlight {
    ${getTypography.headline};
  }

  .highlight-small {
    ${getTypography.subline}
  }

  [id='cookiefirst-policy-page'] {
    svg {
      display: none;
    }
  }

  ${props => props.hasGrid && css(["", " margin:0 ", ";", "{margin:0;}> *{", "}"], getGrid(), forkSpace(6), mq('m'), getTextColumns)}
`;

export default StyledText;
