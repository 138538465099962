import styled$1 from 'styled-components';
import { mq, getProperty, zIndex } from '@ccg/core';
import '@4rk/modular-system';
import '../../../../styles/typography.js';
import 'tinycolor2';
import { forkSpace } from '../../../../styles/spacing.js';
import { getGrid, getOuterMargins } from '../../../Grid/Grid.js';

const StyledOverview = styled$1.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  display: flex;
  align-items: center;
  height: 100vh;

  .overview__framer-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - ${forkSpace(12)});
    height: 100vh;
    overflow: hidden;
    ${getGrid()}
    ${getOuterMargins()}

    ${mq('m')} {
      width: 100%;
    }
  }

  .overview__framer {
    ${getProperty('grid-column', {
  default: '2 / span 5',
  m: '3 / span 5'
})}
    align-self: center;
  }

  .overview__images {
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
    width: 100%;
    padding: 0 ${forkSpace(2)};
    justify-content: center;
    flex-direction: column;
    position: relative;
    z-index: ${zIndex('base')};
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    ${mq('m')} {
      padding: 0 ${forkSpace(24)};
    }
  }

  .overview__row {
    display: flex;
  }

  .overview__image {
    width: 33.33%;
    max-width: 300px;
    flex-shrink: 0;
    margin: ${forkSpace(6)} 0;
    margin-right: ${forkSpace(10)};

    ${mq('m')} {
      margin-right: ${forkSpace(24)};
    }

    img {
      display: block;
      width: 100%;
      max-width: 300px;
      max-height: 35vh;
      height: 100%;
      object-fit: contain;
      filter: ${({
  imageShadow
}) => imageShadow && 'drop-shadow(10px 10px 80px rgba(2,0,0,0.3))'};
    }
  }

  .overview__image-trigger {
    width: 100%;
    align-self: start;
  }
`;

export default StyledOverview;
