import styled$1 from 'styled-components';
import { SpringParallax, mq } from '@ccg/core';
import '@4rk/modular-system';
import '../../styles/typography.js';
import 'tinycolor2';
import { forkSpace } from '../../styles/spacing.js';
import { getTextColumns } from '../../basics/Text/helper/getGridColumns.js';
import { getGrid } from '../Grid/Grid.js';

const StyledForm = styled$1(SpringParallax)`
  position: relative;
  ${getGrid()}
  margin-top: ${forkSpace(12)};
  margin-bottom: ${forkSpace(12)};

  .form__wrapper {
    ${getTextColumns}
  }

  .form__text {
    margin: ${forkSpace(4)} 0;
  }

  .form__form,
  .form__footer {

    grid-column: 1 / span 6;

    ${mq('m')} {
      grid-column: 1 / span 12;
    } 
  }

`;

export default StyledForm;
