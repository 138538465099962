import styled$1, { css } from 'styled-components';
import { getProperty, colorSet } from '@ccg/core';
import '@4rk/modular-system';
import '../../styles/typography.js';
import 'tinycolor2';
import { forkSpace } from '../../styles/spacing.js';
import { getGrid, getOuterMargins } from '../Grid/Grid.js';

const StyledContainer = styled$1.div`
  padding-top: ${forkSpace(12)};
  padding-bottom: ${forkSpace(35)};

  .container__headline {
    ${getGrid()}
    ${getOuterMargins()}
    margin-bottom: ${forkSpace(22)};

    & > * {
      ${getProperty('grid-column', {
  default: '1 / span 6',
  m: '3 / span 10'
})}
    }
  }

  ${props => props.variant === 'default' && css(["background:", ";color:", ";"], colorSet('background'), colorSet('color'))}

  ${props => props.variant === 'inverted' && css(["background:", ";color:", ";"], colorSet('backgroundSecondary'), colorSet('colorSecondary'))}
`;

export default StyledContainer;
