import styled$1 from 'styled-components';
import { zIndex, mq, getProperty } from '@ccg/core';
import '@4rk/modular-system';
import '../../styles/typography.js';
import 'tinycolor2';
import { forkSpace } from '../../styles/spacing.js';
import Grid$1 from '../Grid/Grid.js';

const StyledTeaserOverview = styled$1(Grid$1)`
  position: relative;
  margin-top: ${forkSpace(40)};
  margin-bottom: ${forkSpace(35)};
  z-index: ${zIndex('middle')};

  ${mq('m')} {
    margin-bottom: 0;
  }

  .teaserOverview__headline {
    ${getProperty('grid-column', {
  default: '1 / span 6',
  m: '2 / span 6'
})};
    grid-row: 1;
    margin-bottom: ${forkSpace(6)};
  }

  .teaserOverview__excerpt {
    ${getProperty('grid-column', {
  default: '2 / span 5',
  m: '3 / span 8',
  l: '3 / span 6'
})};
    grid-row: 2;

    p {
      margin-bottom: ${forkSpace(6)};
    }
  }

  + div {
    ${mq('m')} {
      margin-top: ${forkSpace(24)};
    }
  }
`;

export default StyledTeaserOverview;
