import { css } from 'styled-components';
import { getProperty } from '@ccg/core';

const getCaptionAlignment = ({
  variant,
  isRightAligned: right
}) => {
  let m = right ? '7 / span 5' : '2 / span 5';

  if (variant === 'm') {
    m = right ? '10 / span 2' : '2 / span 2';
  } else if (variant === 'l') {
    m = right ? '7 / span 5' : '2 / span 5';
  } else if (variant === 'xl') {
    m = right ? '6 / span 6' : '2 / span 6';
  }

  return css(["", ";", ";"], getProperty('grid-row', {
    default: 2,
    m: variant === 'l' || variant === 'xl' ? 2 : '1 / span 2'
  }), getProperty('grid-column', {
    default: '2 / span 5',
    m
  }));
};
const getImageAlignment = ({
  variant,
  isRightAligned: right
}) => {
  let s = right ? '2 / span 5' : '1 / span 5';
  let m = right ? '2 / span 3' : '9 / span 3';

  if (variant === 'm') {
    m = right ? '2 / span 6' : '6 / span 6';
  } else if (variant === 'l') {
    s = right ? '2 / span 5' : '1 / span 5';
    m = right ? '3 / span 10' : '1 / span 10';
  } else if (variant === 'xl') {
    s = right ? '2 / span 5' : '1 / span 5';
    m = right ? '2 / span 11' : '1 / span 11';
  }

  return css(["grid-row:1;", ";"], getProperty('grid-column', {
    default: s,
    m
  }));
};
const getFramerAlignment = ({
  variant,
  isRightAligned: right
}) => {
  let s = right ? '1 / span 5' : '2 / span 5';
  let m = right ? '3 / span 3' : '8 / span 3';

  if (variant === 'm') {
    m = right ? '3 / span 6' : '5 / span 6';
  } else if (variant === 'l') {
    s = right ? '1 / span 5' : '2 / span 5';
    m = right ? '2 / span 5' : '6 / span 6';
  } else if (variant === 'xl') {
    s = right ? '1 / span 5' : '2 / span 5';
    m = right ? '1 / span 7' : '7 / span 6';
  }

  return css(["grid-row:1;", ";"], getProperty('grid-column', {
    default: s,
    m
  }));
};

export { getCaptionAlignment, getFramerAlignment, getImageAlignment };
