import styled$1, { css } from 'styled-components';
import { SpringParallax, mq, zIndex, getProperty, colorSet } from '@ccg/core';
import '@4rk/modular-system';
import '../../styles/typography.js';
import 'tinycolor2';
import { forkSpace } from '../../styles/spacing.js';

/* eslint-disable no-irregular-whitespace */
const StyledQuote = styled$1(SpringParallax)`
  position: relative;
  margin: ${forkSpace(14)} 0;
  padding: 0;

  ${mq('m')} {
    margin-bottom: 0;
  }

  cite {
    display: block;
    font-style: normal;
    grid-row: 2;
    align-self: center;
    position: relative;
    z-index: ${zIndex('base')};

    ${props => props.hasPhoto ? css(["", ";"], getProperty('grid-column', {
  default: '2 / span 4',
  m: '2 / span 2'
})) : css(["", ";"], getProperty('grid-column', {
  default: '1 / span 6',
  m: '2 / span 2'
}))}
  }

  .quote__content {
    display: flex;
    hyphens: auto;
    margin-bottom: ${forkSpace(6)};
    ${getProperty('grid-column', {
  default: '1 / span 6',
  m: '2 / span 6'
})}
  }

  .quote__icon {
    line-height: 1.45;
    margin-right: ${forkSpace(4)};
  }

  .quote__author {
    transform: translateX(${props => props.hasPhoto ? forkSpace(-6) : 0});

    ${mq('m')} {
      transform: none;
    }
  }

  .quote__author-image-wrapper  {
    grid-row: 2;
    grid-column: 1 / span 1;
    margin-right: ${forkSpace(2)};
  }

  .quote__author-image {
    background: ${colorSet('background')};

    img {
      filter: grayscale(1);
      opacity: 0.8;
    }
  }

  svg {
    margin-bottom: ${forkSpace(6)};
    fill: ${colorSet('color')};
  }
`;

export default StyledQuote;
