import styled$1 from 'styled-components';
import { colorSet, mq } from '@ccg/core';
import '@4rk/modular-system';
import '../../styles/typography.js';
import 'tinycolor2';
import { forkSpace } from '../../styles/spacing.js';

const StyledArticleVideo = styled$1.div`
  width: 100%;
  position: relative;

  .video__play-button {
    width: 100%;
    cursor: pointer;
  }

  .video__poster {
    background: ${colorSet('background')};
    position: relative;

    img {
      opacity: 0.8;
    }
  }

  .video__play-overlay {
    height: 100%;
    position: absolute;
    left: ${forkSpace(6)};
    top: ${forkSpace(6)};
    pointer-events: none;

    ${mq('m')} {
      left: 8%;
      top: 5%;
    }
  }
`;

export default StyledArticleVideo;
