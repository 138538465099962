import styled$1, { css } from 'styled-components';
import { Textarea as Textarea$1, color, colorSet } from '@ccg/core';
import '@4rk/modular-system';
import { getTypography } from '../../styles/typography.js';
import 'tinycolor2';
import { forkSpace } from '../../styles/spacing.js';

const Textarea = styled$1(Textarea$1)`
  textarea {
    width: 100%;
    resize: none;
    background: ${color('medium')};
    outline: none;
    padding: ${forkSpace(3)} ${forkSpace(3)} ${forkSpace(2)};
    border: none;
    border-bottom: ${forkSpace(1)} solid ${colorSet('accent')};

    ${props => props.error && css(["border:", " solid ", ";padding:", ";"], forkSpace(1), colorSet('accent'), forkSpace(2))}
  }

  textarea::placeholder {
    color: inherit;
    opacity: 0.5;
  }

  label {
    display: block;
    margin-bottom: ${forkSpace(2)};
  }

  .textarea__labelText,
  textarea {
    ${getTypography.copy}
  }

  .textarea__optional {
    margin-left: ${forkSpace(2)};
    ${getTypography.small}
  }

  .textarea__description,
  .textarea__error {
    display: block;
    ${getTypography.small}
  }

  .textarea__error {
    color: ${colorSet('accent')};
  }
`;

export default Textarea;
