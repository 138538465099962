import styled$1, { css } from 'styled-components';
import { colorSet, zIndex, mq } from '@ccg/core';

const StyledMainContainer = styled$1.main`
  position: relative;
  background: ${colorSet('background')};
  color: ${colorSet('color')};
  transition: transform 0.7s cubic-bezier(0.77, 0, 0.77, 1), background 0.1s 0.8s ease-in-out,
    color 0.1s 0.8s ease-in-out;
  min-height: 100vh;

  &:before {
    position: absolute;
    top: 40px;
    right: 0;
    width: 200vw;
    height: 200vh;
    background: ${colorSet('background')};
    content: '';
    transform: rotate(-45deg) translate(0%, -50%);
    transform-origin: top right;
    z-index: ${zIndex('bottom')};

    ${mq('m')} {
      display: none;
    }
  }

  ${props => props.navigationIsOpen && css(["transform:translate(-125vw,125vh);pointer-events:none;", "{transform:none;pointer-events:all;}"], mq('m'))}
`;

export default StyledMainContainer;
