import styled$1 from 'styled-components';
import { zIndex, mq, colorSet } from '@ccg/core';
import '@4rk/modular-system';
import { getAccentBottomLine } from '../../styles/typography.js';
import 'tinycolor2';
import { forkSpace } from '../../styles/spacing.js';

/* eslint-disable no-irregular-whitespace */
const StyledFooter = styled$1.div`
  position: relative;
  z-index: ${zIndex('top')};
  margin-top: ${forkSpace(40)};
  padding: 0 ${forkSpace(6)};

  ${mq('l')} {
    flex-wrap: wrap;
    display: flex;
  }

  @keyframes percent {
    ${Array.from(Array(50).keys(), i => `${i}% {
          content: "${i * 2 < 10 ? `0${i * 2}` : i * 2}%";
        }`)}
    51% {
      content: '99%';
    }
  }

  @keyframes blink {
    0% {
      opacity: 0.1;
    }

    20% {
      opacity: 1;
    }

    100% {
      opacity: 0.1;
    }
  }

  @keyframes progress {
    0% {
      transform: scaleX(0.1);
    }

    50% {
      transform: scaleX(1);
    }
  }

  .footer__wrapper {
    margin-bottom: ${forkSpace(6)};

    ${mq('l')} {
      flex-grow: 2;
      display: flex;
      flex-direction: column;
      margin-right: ${forkSpace(2)};
    }
  }

  .footer__progress-wrapper {
    ${mq('m')} {
      display: flex;
      align-items: center;
    }
  }

  .footer__changing {
    color: ${colorSet('accent')};
    margin-right: ${forkSpace(4)};

    span {
      animation-name: blink;
      animation-duration: 1.4s;
      animation-iteration-count: infinite;
      animation-fill-mode: both;

      &:nth-child(2) {
        animation-delay: 0.2s;
      }

      &:nth-child(3) {
        animation-delay: 0.4s;
      }
    }
  }

  .footer__progress-bar {
    width: 100px;
    height: ${forkSpace(2)};
    background: ${colorSet('accent')};
    margin-right: ${forkSpace(2)};
    transform-origin: left center;
    animation-name: progress;
    animation-duration: 8s;
    animation-iteration-count: infinite;
  }

  .footer__progress  {
    display: flex;
    align-items: center;
    margin: ${forkSpace(1)} 0;
  }

  .footer__progress-count {
    position: relative;
    color: ${colorSet('accent')};
    margin-right: ${forkSpace(4)};

    &:before {
      position: relative;
      content: '99%';
      animation-name: percent;
      animation-duration: 8s;
      animation-iteration-count: infinite;
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
      margin: ${forkSpace(1)} 0;

      ${mq('m')} {
        display: inline-block;
        margin-left: ${forkSpace(6)};
      }
    }
  }

  .footer__sub-navigation {
    margin: 0 0 ${forkSpace(6)};

    ul {
      display: inline-block;
    }

    li:first-of-type {
      margin-left: 0;
    }
  }

  .footer__main-navigation {
    margin-top: ${forkSpace(12)};
    display: flex;
    flex-direction: column;

    ${mq('m')} {
      flex-direction: row;
      align-items: center;
    }

    a.is-active {
      & > span {
        ${getAccentBottomLine}
      }

      .icon__icon {
        background: none;
      }

      &:hover {
        background: transparent;
        color: inherit;
      }
    }
  }
`;

export default StyledFooter;
