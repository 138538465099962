import styled$1 from 'styled-components';
import { mq, getProperty } from '@ccg/core';
import '@4rk/modular-system';
import '../../styles/typography.js';
import 'tinycolor2';
import { forkSpace } from '../../styles/spacing.js';
import Grid$1 from '../Grid/Grid.js';

const StyledMediaRow = styled$1(Grid$1)`
  position: relative;
  margin: ${forkSpace(10)} 0;

  ${mq('m')} {
    margin: ${forkSpace(16)} 0;
  }

  .mediaRow__item {
    ${props => props.itemsPerRow === '2' ? getProperty('grid-column-end', {
  default: 'span 6',
  m: 'span 6'
}) : getProperty('grid-column-end', {
  default: 'span 12',
  m: 'span 10'
})};

    ${mq('m')} {
      grid-column-start: ${props => props.itemsPerRow !== '2' && 2};
    }

    img,
    video {
      width: 100%;
      display: block;
    }
  }

  .mediaRow__item-meta {
    margin: ${forkSpace(10)} 0;
    padding: 0 ${forkSpace(6)};

    ${mq('m')} {
      margin: ${forkSpace(16)} 0;
      padding: 0 17%;
    }
  }
`;

export default StyledMediaRow;
