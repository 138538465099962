import styled$1, { css } from 'styled-components';
import { mq, color, colorSet, space, zIndex } from '@ccg/core';
import { unit } from '@4rk/modular-system';
import { getAccentBottomLine } from '../../styles/typography.js';
import 'tinycolor2';
import { forkSpace } from '../../styles/spacing.js';
import { getTransitionProperty } from '../../styles/transitions.js';

const StyledNavigation = styled$1.div`
  .navigation__wrapper {
    display: flex;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;

    ${mq('m')} {
      z-index: 1;
    }
  }

  .navigation__list {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    padding: ${forkSpace(25)} ${forkSpace(6)};
    color: ${color('light')};
    background: ${color('bright')};
    overflow-y: scroll;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;

    display: grid;
    grid-column-gap: ${unit('density', 'gridGap')};
    grid-template-columns: repeat(6, 1fr);

    ${mq('m')} {
      position: relative;
      display: block;
      width: 100%;
      padding: 0;
      height: auto;
      background: transparent;
      color: ${colorSet('color')};
      overflow-y: visible;
      overflow-x: visible;
      z-index: 100;
    }
  }

  ul {
    width: 100%;
    margin: 0;
    padding: 0;
    max-width: 100%;
  }

  .navigation__list-wrapper {
    margin-bottom: ${forkSpace(25)};
    grid-column: 3 / span 4;

    ${mq('m')} {
      margin-bottom: 0;
      grid-column: none;
    }
  }

  li {
    /* position: relative; */
    display: block;
    list-style-type: none;

    a {
      ${mq('m', 'max')} {
        &:not([target='_blank']) {
          .icon__icon {
            background: ${color('light')};
          }
        }

        &[target='_blank'] {
          .icon__icon {
            background: none;
            border-top-color: ${color('light')};
          }
        }

        &:hover {
          background: transparent;
          color: inherit;
        }
      }
    }
  }

  .navigation__mainlist {
    & > li {
      position: relative;
      margin: 0 0 ${forkSpace(12)};

      &:hover .navigation__mainlist-sub,
      &:focus-within .navigation__mainlist-sub {
        opacity: 1;
        visibility: visible;
        transform: scale(1);
        transition: transform 400ms ease-in-out, opacity step-start 400ms,
          visibility step-start 400ms;

        li {
          opacity: 1;
          visibility: visible;
          transition: all 400ms ease-in-out 700ms;
        }

        li:first-child {
          transform: rotate(90deg);
          transition: all 200ms ease-in-out 400ms;
        }
      }

      &:last-child {
        position: absolute;
        top: ${forkSpace(80)};
        left: 0;
        transform: rotate(270deg);
        transform-origin: bottom;

        ${mq('m')} {
          top: 0;
          right: 0;
          left: auto;
          transform: none;
        }

        a .icon__icon {
          display: none;
        }
      }

      & > a.is-active {
        & > span {
          border-bottom: ${space(1)} solid ${color('light')};
          background: linear-gradient(180deg, transparent 78%, ${color('light')} 78%);

          ${mq('m')} {
            ${getAccentBottomLine}
          }
        }

        .icon__icon {
          background: none;
        }

        &:hover {
          background: transparent;
          color: inherit;
        }
      }

      a {
        ${mq('m', 'max')} {
          &:not([target='_blank']) {
            .icon__icon {
              background: ${color('light')};
            }
          }

          &[target='_blank'] {
            .icon__icon {
              background: none;
              border-top-color: ${color('light')};
            }
          }

          &:hover {
            background: transparent;
            color: inherit;
          }
        }
      }

      ${mq('m')} {
        display: inline-block;
        margin: 0;
        padding: ${forkSpace(4)} ${forkSpace(4)} ${forkSpace(2)};
      }
    }
  }

  .navigation__mainlist-sub {
    flex-direction: column;
    display: flex;
    padding: ${forkSpace(6)} 0 0;

    ${mq('m')} {
      display: inline-flex;
      opacity: 0;
      visibility: hidden;
      padding: ${forkSpace(2)} 0 ${forkSpace(2)} ${forkSpace(10)};
      position: absolute;
      background: ${colorSet('accent')};
      right: ${forkSpace(4)};
      top: 1.188rem;
      width: auto;
      min-width: 200px;
      min-height: 200px;
      z-index: ${zIndex('top')};
      transform: scale(0);
      transform-origin: top right;
      transition: transform 400ms ease-in-out, opacity step-end 400ms, visibility step-end 400ms;
    }

    li:first-child {
      display: none;

      a {
        line-height: 1;
      }

      ${mq('m')} {
        display: block;
        position: absolute;
        top: 5px;
        left: 40px;
        width: 100%;
        transform-origin: 0 0;
        transform: rotate(60deg);
      }
    }

    li {
      margin: 0;
      padding: ${forkSpace(2)} 0 ${forkSpace(2)} ${forkSpace(6)};

      ${mq('m')} {
        padding: ${forkSpace(2)} ${forkSpace(4)};
        opacity: 0;
        visibility: hidden;
      }

      a {
        .icon__icon {
          background: ${colorSet('background')};
        }

        &[target='_blank'] {
          .icon__icon {
            background: none;
            border-top-color: ${color('dark')};
          }
        }

        ${mq('m', 'max')} {
          &:not([target='_blank']) {
            .icon__icon {
              background: ${color('dark')};
            }
          }

          &[target='_blank'] {
            .icon__icon {
              background: none;
              border-top-color: ${color('dark')};
            }
          }

          &:hover {
            background: transparent;
            color: inherit;
          }
        }

        &:hover {
          ${mq('m')} {
            background: ${colorSet('background')};
          }
        }
      }

      a.is-active {
        & > span {
          border-bottom: ${space(1)} solid ${color('dark')};
          background: linear-gradient(180deg, transparent 78%, ${color('dark')} 78%);

          ${mq('m')} {
            border-bottom: ${space(1)} solid ${colorSet('background')};
            background: linear-gradient(180deg, transparent 78%, ${colorSet('background')} 78%);
          }
        }

        .icon__icon {
          background: none;
        }

        &:hover {
          background: transparent;
          color: inherit;
        }
      }
    }
  }

  .navigation__sublist {
    position: relative;
    margin-top: ${space(4)};

    &:before {
      content: '';
      position: relative;
      background: ${color('light')};
      width: ${space(25)};
      height: ${space(2)};
      margin-bottom: ${space(6)};
      display: block;
    }

    li {
      margin: 0 0 ${forkSpace(4)};
    }

    ${mq('m')} {
      display: none;
    }
  }

  button {
    display: block;
    padding: ${forkSpace(4)} ${forkSpace(6)};
    color: ${props => props.isOpen ? color('light') : 'inherit'};
    position: absolute;
    top: 0;
    right: 0;
    z-index: 99;

    ${mq('m')} {
      display: none;
    }
  }

  .navigation__curtain {
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;
    background: ${color('bright')};
    z-index: 1;
    clip-path: polygon(100% 0, 0 0, 100% 100%);
    ${getTransitionProperty('transform')}

    ${mq('m')} {
      display: none;
    }

    ${props => props.isOpen && css(["transform:translate(-125vw,125vh);"])}
  }

  .navigation__logo {
    position: relative;
    margin: ${forkSpace(4)};
    z-index: 99;
    ${getTransitionProperty('transform')}

    svg {
      width: auto;
      height: 17px;
    }

    ${props => props.isOpen && css(["transform:translate(-125vw,125vh);", "{transform:none;}"], mq('m'))}
  }

  .navigation__mobile-logo {
    position: absolute;
    top: ${forkSpace(25)};
    left: ${forkSpace(-35)};
    transform: rotate(-90deg);
    transform-origin: top right;
    padding-right: ${forkSpace(1)};

    svg {
      fill: ${color('light')};
    }

    ${mq('m')} {
      display: none;
    }
  }
`;

export default StyledNavigation;
