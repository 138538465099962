import styled$1, { css } from 'styled-components';
import { getProperty, mq } from '@ccg/core';
import '@4rk/modular-system';
import '../../styles/typography.js';
import 'tinycolor2';
import { forkSpace } from '../../styles/spacing.js';

const StyledStage = styled$1.div`
  margin-bottom: ${forkSpace(22)};

  .stage__image {
    ${getProperty('grid-column', {
  default: '1 / span 6',
  m: '7 / span 6'
})};
    ${getProperty('grid-row', {
  m: '1 / span 2'
})};
    margin-right: ${forkSpace(-6)};
    margin-left: ${forkSpace(-6)};
    margin-bottom: ${forkSpace(-14)};

    ${mq('m')} {
      margin-right: 0;
      margin-left: 0;
    }
  }

  .stage__headline {
    ${getProperty('grid-column', {
  default: '1 / span 6',
  m: '2 / span 7'
})};
    ${getProperty('grid-row', {
  m: '1'
})};
    align-self: end;
    position: relative;

    ${mq('m')} {
      margin-top: ${forkSpace(22)};
    }

    h1 {
      word-break: normal;
    }

    ${props => !props.hasMedia && css(["margin-top:", ";", "{margin-top:", ";}"], forkSpace(20), mq('m'), forkSpace(40))}
  }

  .stage__subline {
    ${getProperty('grid-column', {
  default: '2 / span 5',
  m: '2 / span 6'
})};
    ${getProperty('grid-row', {
  m: '2'
})};
    position: relative;
  }

  img,
  video {
    width: 100%;
  }
`;

export default StyledStage;
