import styled$1 from 'styled-components';
import { getProperty, zIndex, colorSet } from '@ccg/core';
import '../../styles/typography.js';
import 'tinycolor2';
import { forkSpace } from '../../styles/spacing.js';
import { getTransitionProperty } from '../../styles/transitions.js';
import Grid$1, { getGrid } from '../Grid/Grid.js';
import Link$1 from '../../basics/Link/Link.js';

const StyledMemberItem = styled$1(Link$1)`
  ${getProperty('grid-column', {
  default: '1 / span 6',
  m: '3 / span 8'
})};
  position: relative;
  padding-bottom: ${forkSpace(12)};
  cursor: pointer;

  .memberItem__image-wrapper {
    position: absolute;
    z-index: ${zIndex('base')};
    pointer-events: none;
    opacity: 0;
    top: 0;
    left: 0;
    width: 212px;
    padding-bottom: 0;
    transform: translate(-50%, -50%);
    ${getTransitionProperty('opacity')}

    @media (hover: none) and (pointer: coarse) {
      display: none;
    }

    &.faded-out {
      opacity: 0;
      transition: opacity 2s ease 1s;
    }

    &.faded-in {
      opacity: 1;
      z-index: ${zIndex('middle')};
    }
  }

  .memberItem__content {
    ${getGrid({
  default: 6,
  m: 8
})};
    position: relative;
    z-index: ${zIndex('top')};
  }

  .memberItem__name,
  .memberItem__title {
    margin-top: ${forkSpace(2)};
  }

  .memberItem__name {
    ${getProperty('grid-column', {
  default: '1 / span 3'
})};

    p {
      display: inline;
      hyphens: none;
    }
  }

  .memberItem__title {
    ${getProperty('grid-column', {
  default: '4 / span 3',
  m: '4 / span 4'
})};
    padding-left: ${forkSpace(4)};
  }

  &:hover .memberItem__name p {
    color: ${colorSet('color')};
    background: ${colorSet('accent')};
  }
`;
const StyledTeamList = styled$1(Grid$1)`
  margin-bottom: ${forkSpace(22)};
`;

export default StyledTeamList;
export { StyledMemberItem };
