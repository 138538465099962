import styled$1 from 'styled-components';
import { mq, colorSet, zIndex } from '@ccg/core';
import '@4rk/modular-system';
import '../../styles/typography.js';
import 'tinycolor2';
import { forkSpace } from '../../styles/spacing.js';

const StyledGallery = styled$1.div`
  margin: ${forkSpace(12)} 0;

  .gallery__ratio {
    img {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, 0);
      width: 100%;
      height: 100%;
      object-fit: cover;

      &:nth-of-type(1) {
        transform: translate(calc(-50% - 20px), 20px);

        ${mq('l')} {
          transform: translate(calc(-50% - 40px), 30px);
        }
      }

      &:nth-of-type(2) {
        transform: translate(calc(-50% - 10px), 10px);

        ${mq('l')} {
          transform: translate(calc(-50% - 20px), 15px);
        }
      }
    }
  }

  .gallery__layer {
    position: absolute;
    top: 0;
    right: 0;
    width: 120px;
    height: 120px;
    background: ${colorSet('accent')};
    color: ${colorSet('color')};
    clip-path: polygon(100% 0, 0 0, 100% 100%);
    transition: all 200ms ease;

    span {
      position: absolute;
      top: 14px;
      right: 56px;
      opacity: 0;
      width: 50px;
      line-height: 1;
      text-align: right;
    }

    svg {
      position: absolute;
      top: 16px;
      right: 16px;
      fill: ${colorSet('color')};
    }
  }

  .gallery__button {
    position: relative;
    z-index: ${zIndex('base')};
    width: 100%;

    &:hover .gallery__layer {
      width: 200px;
      height: 200px;

      span {
        opacity: 1;
      }
    }
  }
`;

export default StyledGallery;
