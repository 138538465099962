import styled$1 from 'styled-components';
import { getProperty } from '@ccg/core';
import '@4rk/modular-system';
import '../../styles/typography.js';
import 'tinycolor2';
import { forkSpace } from '../../styles/spacing.js';

const StyledCoverStage = styled$1.div`
  position: relative;
  margin-bottom: ${forkSpace(22)};

  .coverStage__media-wrapper {
    position: ${({
  $fixedImage
}) => $fixedImage ? 'fixed' : 'absolute'};
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    pointer-events: none;

    .coverStage__media {
      width:100%;
      height:100%;
    }

    img,
    video {
      object-fit: cover;
      opacity: 0.3; 
      width: 100%;
      height: 100%;
    }
  }

  .coverStage__client {
    margin-top: ${forkSpace(-5)};
    margin-bottom: ${forkSpace(-5)};
    ${getProperty('word-break', {
  default: 'break-all',
  m: 'normal'
})};
    ${getProperty('grid-column', {
  default: '1 / span 6',
  m: '1 / span 12'
})};
  }

  .coverStage__intro {
    padding-top: ${forkSpace(25)};
    margin-bottom: ${forkSpace(9)};
  }

  .coverStage__breadcrumbLink,
  .coverStage__headlines {
    ${getProperty('grid-column', {
  default: '1 / span 6',
  m: '2 / span 6'
})}
  }

  .coverStage__breadcrumbLink {
    margin-bottom: ${forkSpace(6)};
  }

  .coverStage__breadcrumbLink a {
    display: inline;
  }

  .coverStage__headline {
    margin-bottom: ${forkSpace(6)};
  }

  .coverStage__excerpt {
    ${getProperty('grid-row', {
  default: 3,
  m: 2
})}
    ${getProperty('grid-column', {
  default: '1 / span 6',
  m: '6 / span 6'
})}
    margin: ${forkSpace(22)} 0 0;
  }

  .coverStage__subline {
    margin-bottom: ${forkSpace(35)};
  }
  
  .coverStage__tags {
    margin: ${forkSpace(6)} 0 ${forkSpace(2)};
  }
`;

export default StyledCoverStage;
