import styled$1 from 'styled-components';
import { colorSet } from '@ccg/core';
import '@4rk/modular-system';
import '../../styles/typography.js';
import 'tinycolor2';
import { getTransitionProperty } from '../../styles/transitions.js';

const StyledRevealBox = styled$1.div`
  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 2;
    transform: translateX(${props => props.isVisible ? 'calc(-100% - 1px)' : '0'});
    background: ${props => props.revealColor || colorSet('accent')(props)};
    transform-origin: left center;

    ${getTransitionProperty('transform')}
  }
`;

export default StyledRevealBox;
