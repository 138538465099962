import React, { useEffect, useContext, FC } from 'react';
import { animated, useSpring } from 'react-spring';

import { randomInt } from '../../helper/index';
import { ScrollContext } from '../../contexts/ScrollProvider/ScrollProvider';

export interface SpringParallaxProps {
  range?: [number, number];
  className?: string;
}

const SpringParallax: FC<SpringParallaxProps> = ({ children, className, range = [0, -60] }) => {
  const { scrollYPercentage, scrollDirection } = useContext(ScrollContext);

  const config = {
    mass: randomInt(4, 5),
    tension: randomInt(400, 500),
    friction: randomInt(80, 100),
    velocity: 0.01
  };

  const [{ y }, set] = useSpring(() => ({
    y: 0,
    from: { y: 0 },
    config
  }));

  useEffect(() => {
    const [min, max] = range;
    set({
      y: scrollYPercentage * (max - min) + min,
      config: { ...config, velocity: scrollDirection === 'down' ? 20 : -20 },
      delay: randomInt(10, 40)
    });
  }, [scrollYPercentage]);

  return (
    <animated.div
      className={className}
      style={{
        transform: y.interpolate(o => `translateY(${o}px)`)
      }}
    >
      {children}
    </animated.div>
  );
};

export default SpringParallax;
