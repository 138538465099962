import styled$1, { css } from 'styled-components';
import { Input as Input$1, color, colorSet } from '@ccg/core';
import '@4rk/modular-system';
import { getTypography } from '../../styles/typography.js';
import 'tinycolor2';
import { forkSpace } from '../../styles/spacing.js';

const Input = styled$1(Input$1)`
  input {
    width: 100%;
    background: ${color('medium')};
    outline: none;
    padding: ${forkSpace(3)} ${forkSpace(3)} ${forkSpace(2)};
    border: none;
    border-bottom: ${forkSpace(1)} solid ${colorSet('accent')};

    ${props => props.error && css(["border:", " solid ", ";padding:", ";"], forkSpace(1), colorSet('accent'), forkSpace(2))}
  }

  input::placeholder {
    color: inherit;
    opacity: 0.5;
  }

  label {
    display: block;
    margin-bottom: ${forkSpace(2)};
  }

  .input__labelText,
  input {
    ${getTypography.copy}
  }

  .input__optional {
    margin-left: ${forkSpace(2)};
    ${getTypography.small}
  }

  .input__description,
  .input__error {
    display: block;
    ${getTypography.small}
  }

  .input__error {
    color: ${colorSet('accent')};
  }
`;

export default Input;
