import styled$1 from 'styled-components';
import { colorSet, mq } from '@ccg/core';
import '@4rk/modular-system';
import { getAccentTopLine } from '../../styles/typography.js';
import 'tinycolor2';
import { forkSpace } from '../../styles/spacing.js';
import Text$1 from '../../basics/Text/Text.js';

const StyledFact = styled$1(Text$1)`
  position: relative;

  .highlight {
    display: block;
    color: ${colorSet('accent')};
    word-break: break-all;
    letter-spacing: -5px;
    line-height: 1;

    b,
    strong {
      background: transparent;
      color: ${colorSet('accent')};
    }
  }

  .highlight-small {
    letter-spacing: 0px;
  }

  .fact__content {
    position: relative;
    grid-column: 1 / span 6;
    align-self: center;
    ${getAccentTopLine};
    margin: ${forkSpace(6)} 0 ${forkSpace(12)} 0;
    padding: ${forkSpace(18)} 0 ${forkSpace(18)} ${forkSpace(18)};
    color: ${colorSet('colorSecondary')};
    ${getAccentTopLine}

    p,a,.highlight {
      position: relative;
      z-index: 2;
    }

    a {
      &:hover {
        color: ${colorSet('colorSecondary')};
        background: ${colorSet('accent')};
      }
    }

    p {
      margin: 0;
    }

    ${mq('m')} {
      grid-column: ${({
  theme
}) => theme.contentType === 'advertising' ? '1 / span 5' : '8 / span 5'};
      grid-row: 1 / span 10;
      margin: 0;
      padding: ${forkSpace(18)};
    }

    ${mq('l')} {
      grid-column: ${({
  theme
}) => theme.contentType === 'advertising' ? '1 / span 4' : '9 / span 4'};
    }

    &:after {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      height: 100%;
      background: ${colorSet('color')};
      width: calc(100% + ${forkSpace(6)});

      ${mq('m')} {
        width: 100%;
        margin-left: 0;
      }
    }
  }

  .fact__content:before {
    position: absolute;
    top: ${forkSpace(21)};
    left: -50px;
    z-index: 1;

    ${mq('m')} {
      left: auto;
      ${({
  theme
}) => theme.contentType === 'advertising' ? 'right' : 'left'}: -50px;
    }
  }
`;

export default StyledFact;
