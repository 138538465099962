import styled$1, { css } from 'styled-components';
import { space, colorSet } from '@ccg/core';

const StyledIcon = styled$1.div`
  display: inline;
  white-space: nowrap;

  .icon__icon {
    display: inline-block;
    position: relative;
    width: 0;
    height: 0;

    ${({
  icon
}) => {
  switch (icon) {
    case 'arrowUp':
      return css(["border-left:", " solid transparent;border-right:", " solid transparent;border-bottom:", " solid ", ";"], space(1.5), space(1.5), space(2), colorSet('accent'));

    case 'arrowDown':
      return css(["border-left:", " solid transparent;border-right:", " solid transparent;border-top:", " solid ", ";"], space(1.5), space(1.5), space(2), colorSet('accent'));

    case 'arrowLeft':
      return css(["border-top:", " solid transparent;border-right:", " solid ", ";border-bottom:", " solid transparent;"], space(1.5), space(2), colorSet('accent'), space(1.5));

    case 'arrowRight':
      return css(["border-top:", " solid transparent;border-left:", " solid ", ";border-bottom:", " solid transparent;"], space(1.5), space(1.5), colorSet('accent'), space(1.5));

    case 'close':
      return css(["border-top:", " solid transparent;border-left:", " solid ", ";border-right:", " solid ", ";border-bottom:", " solid transparent;"], space(1.5), space(2), colorSet('accent'), space(2), colorSet('accent'), space(1.5));

    case 'external':
      return css(["vertical-align:text-top;border-top:", " solid ", ";border-left:", " solid transparent;"], space(2), colorSet('accent'), space(2));

    case 'download':
      return css(["vertical-align:text-bottom;border-bottom:", " solid ", ";border-left:", " solid transparent;"], space(2), colorSet('accent'), space(2));

    case 'contact':
      return css(["vertical-align:text-top;border-bottom:", " solid ", ";border-right:", " solid transparent;"], space(2), colorSet('accent'), space(2));

    default:
      return css(["width:", ";height:", ";background:", ";vertical-align:text-top;"], space(2), space(2), colorSet('accent'));
  }
}}
  }
`;

export default StyledIcon;
