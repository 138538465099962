import styled$1, { css } from 'styled-components';
import { Checkbox as Checkbox$1, hideVisually, colorSet, zIndex } from '@ccg/core';
import '@4rk/modular-system';
import { getTypography } from '../../styles/typography.js';
import 'tinycolor2';
import { forkSpace } from '../../styles/spacing.js';

const getRectangle = (width, col) => css(["width:", "px;height:", "px;background-color:", ";position:relative;"], width, width, col);

const Checkbox = styled$1(Checkbox$1)`
  margin-bottom: ${forkSpace(2)};
  opacity: ${props => props.disabled && '0.5'};

  input {
    ${hideVisually}
  }

  label {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .checkbox__custom-checkbox {
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    margin: ${forkSpace(2)};
    margin-left: 0;

    ${getRectangle(26, colorSet('neutral'))}
  }

  .checkbox__custom-checkbox-inner {
    position: relative;
    width: 10px;
    height: 20px;
    display: none;
    z-index: ${zIndex('base')};
    transform: rotate(45deg);

    border-bottom: ${forkSpace(1)} solid ${colorSet('accent')};
    border-right: ${forkSpace(1)} solid ${colorSet('accent')};
  }

  .checkbox__label {
    display: block;
    ${getTypography.copy}
  }

  .checkbox__optional {
    margin-left: ${forkSpace(2)};
    ${getTypography.small}
  }

  .checkbox__headline,
  .checkbox__error {
    display: block;
    ${getTypography.small}
  }

  .checkbox__error {
    color: ${colorSet('accent')};
  }

  input:checked + .checkbox__custom-checkbox .checkbox__custom-checkbox-inner {
    display: block;
  }

  input[disabled] + .checkbox__custom-checkbox {
    background: ${colorSet('background')};
    border: 2px solid ${colorSet('color')};

    .checkbox__custom-checkbox-inner {
      display: none;
    }
  }
`;

export default Checkbox;
