import styled$1, { css } from 'styled-components';
import { mq, colorSet } from '@ccg/core';
import '../../styles/typography.js';
import 'tinycolor2';
import { forkSpace } from '../../styles/spacing.js';
import { getTransition } from '../../styles/transitions.js';
import { getGrid } from '../Grid/Grid.js';

const StyledTeaser = styled$1.div`
  align-self: center;
  grid-row-end: ${({
  image
}) => image ? 'span 2' : 'span 1'};
  ${getTransition}

  .teaser__topic,
  .teaser__headline, 
  .teaser__tags {
    grid-column: 2 / span 5;

    ${mq('m')} {
      grid-column: 2 / span 3;
    }
  }

  .teaser__topic {
    margin-bottom: ${forkSpace(4)};
  }

  .teaser__headline {
    h2 {
      display: inline;
    }
  }

  a:hover .teaser__headline h2 {
    color: ${colorSet('color')};
    background: ${colorSet('accent')};
  }

  .teaser__tags {
    margin-bottom: ${forkSpace(2)};
    margin-top: 0;
    grid-column: 2 / span 5;

    ${mq('m')} {
      grid-column: 2 / span 4;
    }
  }

  .teaser__media {
    margin-bottom: ${forkSpace(6)};
    grid-column: 1 / span 6;

    img,
    video {
      display: block;
      width: 100%;
    }

    ${mq('m')} {
      grid-row: 1;
    }
  }

  .teaser__link {
    display: grid;
    ${getGrid({
  default: 6
})}
  }

  ${({
  topic,
  accentColor
}) => topic && accentColor && css([".teaser__topic{}.icon__icon{background:", ";}a:hover .teaser__headline h2{color:", ";background:", ";}"], accentColor.secondary, accentColor.primary, accentColor.secondary)}
`;

export default StyledTeaser;
