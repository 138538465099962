import styled$1, { css } from 'styled-components';
import { SpringParallax, colorSet } from '@ccg/core';
import { unit, cubicBezier } from '@4rk/modular-system';
import { font } from '../../styles/typography.js';
import 'tinycolor2';
import { forkSpace } from '../../styles/spacing.js';

/* eslint-disable no-irregular-whitespace */

const cubic = props => cubicBezier('acceleration', 'cubicBezier')(props);

const StyledCaption = styled$1(SpringParallax)`
  margin: ${forkSpace(4)} 0;

  .caption__caption  {
    p {
      margin: 0;
    }
  }

  .caption__credits {
    margin-top: ${forkSpace(2)};
  }

  .caption__fig {
    padding: ${forkSpace(1)} ${forkSpace(2)} 0 0;
    float: left;
    ${font(0)}

    &:after {
      content: 'Fig. ' counter(captions);
      ${({
  increment
}) => increment && css(["counter-increment:captions;"])}
    }
  }

  svg {
    width: 15px;
    height: 15px;
    fill: ${colorSet('accent')};
    transition: all ${unit('gravity', 'transitionDuration')} ${cubic} 0.4s;

    ${({
  isVisible,
  rotateTo
}) => isVisible && css(["transform:rotate(", ");"], rotateTo === 'left' ? '-90deg' : '90deg')}
  }
`;

export default StyledCaption;
