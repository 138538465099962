import styled$1 from 'styled-components';
import { Button, colorSet } from '@ccg/core';
import '@4rk/modular-system';
import { getTypography, getAccentTopLine } from '../../styles/typography.js';
import 'tinycolor2';

const StyledButton = styled$1(Button)`
  display: block;
  color: inherit;
  text-transform: uppercase;
  ${getTypography.copy};
  ${({
  variant
}) => variant === 'primary' ? getAccentTopLine : ''};

  &:hover {
    .button__label {
      color: ${colorSet('color')};
      background: ${colorSet('accent')};
    }
  }

  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export default StyledButton;
